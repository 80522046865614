import clsx from 'clsx';

import Dropdown from '@components/drop-down/Dropdown';
import IndiaFlagIcon from '@assets/images/flag-india.svg';
import UaeFlagIcon from '@assets/images/flag-uae.svg';
import UsFlagIcon from '@assets/images/flag-us.svg';

import { CountryCodeProps } from './types';
import { CountryCodes } from './constants';

const CountryCode = (props: CountryCodeProps) => {
  const {
    className,
    hasSelection,
    countryCode = CountryCodes.INDIA,
    setCountryCode,
    listId
  } = props;

  const renderOption = (label: string, imgSrc: string) => (
    <div className="flex items-center gap-3">
      <img src={imgSrc} className="size-5" alt="india-flag" />
      <div className="text-base font-medium text-content">{label}</div>
    </div>
  );

  const flagIcon = {
    [CountryCodes.INDIA]: IndiaFlagIcon,
    [CountryCodes.UAE]: UaeFlagIcon,
    [CountryCodes.US]: UsFlagIcon
  };

  const CountryCodeOptions = [
    {
      id: CountryCodes.INDIA,
      label: CountryCodes.INDIA,
      value: CountryCodes.INDIA,
      element: renderOption(CountryCodes.INDIA, IndiaFlagIcon)
    },
    {
      id: CountryCodes.UAE,
      label: CountryCodes.UAE,
      value: CountryCodes.UAE,
      element: renderOption(CountryCodes.UAE, UaeFlagIcon)
    },
    {
      id: CountryCodes.US,
      label: CountryCodes.US,
      value: CountryCodes.US,
      element: renderOption(CountryCodes.US, UsFlagIcon)
    }
  ];

  return (
    <div className={className}>
      {hasSelection && setCountryCode ? (
        <Dropdown
          currentFilter={countryCode}
          filterOptions={CountryCodeOptions}
          onSelectFilter={(option) => setCountryCode?.(option as CountryCodes)}
          className="!h-12.5 !min-w-30 !rounded-lg !bg-grey10"
          optionsStyle="left-0 !min-w-40 !max-w-40"
          listId={listId}
        />
      ) : (
        <div
          className={clsx(
            'flex h-12.5 items-center justify-start gap-2 rounded-xl border bg-grey10 p-4',
            className
          )}
        >
          <img
            src={flagIcon[countryCode]}
            className={`w-4.5 ${
              countryCode === CountryCodes.UAE ? 'h-4.5' : 'h-3'
            }`}
            alt="india-flag"
          />
          <div className="text-content">{countryCode}</div>
        </div>
      )}
    </div>
  );
};

export default CountryCode;
