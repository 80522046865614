import { GROUPS } from '@constants/routes';
import { getGroupsHome } from '@utils/generic';
import { FormDataType, GroupDetailsType } from '@types';
import { TabPill } from 'types/tabs';

export enum GroupType {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const groupFilterOptions = [
  { id: 1, label: 'All Groups', value: GroupType.ALL },
  { id: 2, label: 'Active Groups', value: GroupType.ACTIVE },
  { id: 3, label: 'Inactive Groups', value: GroupType.INACTIVE }
];

// Add New Group

export const addNewGroupTabs = [
  {
    id: 1,
    label: 'Import File'
  },
  {
    id: 2,
    label: 'Add Manually'
  }
];

export const acceptedMemberAdditionFileFormats = '.xlsx, .xls, .csv';

export enum PaymentCollectionType {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  BY_TERM = 'by_term',
  ONCE = 'once'
}

export const PaymentCollectionOptions = [
  { id: 1, label: 'Monthly', value: PaymentCollectionType.MONTHLY },
  { id: 2, label: 'Weekly', value: PaymentCollectionType.WEEKLY },
  { id: 3, label: 'By term', value: PaymentCollectionType.BY_TERM },
  { id: 4, label: 'Once', value: PaymentCollectionType.ONCE }
];

export enum MemberType {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const memberFilterOptions = [
  { id: 1, label: 'All Members', value: MemberType.ALL },
  { id: 2, label: 'Active Members', value: MemberType.ACTIVE },
  { id: 3, label: 'Inactive Members', value: MemberType.INACTIVE }
];

export type MemberTableRowDataType = {
  id: string;
  name: string;
  lastPaid: string;
  feeAmount: string;
  dueDate: string;
};

export const memberTableColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    style: 'group-hover:underline !font-medium',
    width: '40%'
  },
  { Header: 'Amount', accessor: 'feeAmount', width: '20%' },
  { Header: 'Due Date', accessor: 'dueDate', width: '25%' },
  { Header: 'Actions', accessor: 'actions' }
];

export const attendanceTableColumn = [
  { Header: 'Sl.No', accessor: 'slotNumber', width: '10%' },
  {
    Header: 'Name',
    accessor: 'name',
    width: '60%'
  },
  { Header: 'Status', accessor: 'status', width: '30%' }
];

export const addMemberTableColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    style: 'group-hover:underline !font-medium',
    width: '35%'
  },
  { Header: 'Primary Number', accessor: 'primaryNumber', width: '15%' },
  { Header: 'Alternate Number', accessor: 'altNumber', width: '15%' },
  { Header: 'Amount', accessor: 'feeAmount' },
  { Header: 'Due Date', accessor: 'dueDate' },
  { Header: 'Actions', accessor: 'actions' }
];

export const defaultTemplateHeaders = (
  haveCategory: boolean,
  isEmailEnabled: boolean,
  isAmountPaidTillDateEnabled: boolean,
  isOnce: boolean
) => [
  'name',
  'primaryNumberCode',
  'primaryNumber',
  'altNumberCode',
  'altNumber',
  ...(isEmailEnabled ? ['email'] : []),
  ...(haveCategory ? [] : ['amount']),
  ...(isAmountPaidTillDateEnabled ? ['amountPaid'] : []),
  ...(!isOnce ? ['dueDate'] : [])
];

export const templateDummayData = (
  haveCategory: boolean,
  isEmailEnabled: boolean,
  isAmountPaidTillDateEnabled: boolean,
  isOnce: boolean
) => [
  'Sam',
  '91',
  'XXXXXXXXXX',
  '91',
  'XXXXXXXXXX',
  ...(isEmailEnabled ? ['XXXXXX@gmail.com'] : []),
  ...(haveCategory ? [] : ['1000']),
  ...(isAmountPaidTillDateEnabled ? [''] : []),
  ...(!isOnce ? ['2024-02-14'] : [])
];

export const editGroupInfoInitialState = {
  groupPhoto: { file: '', preview: '', image_url: '' },
  name: '',
  amount: '',
  next_due_at: '',
  frequency: '',
  interval_frequency: 0,
  paymentInstallment: false,
  end_date: '',
  isAttandanceTrackingEnabled: false,
  isSendAttendanceAlertEnabled: false,
  workingDays: []
};

export const groupInfoAddMemberTableColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    style: 'group-hover:underline !font-medium',
    width: '20%'
  },
  { Header: 'Primary Number', accessor: 'primaryNumber', width: '12%' },
  { Header: 'Alternate Number', accessor: 'altNumber', width: '12%' },
  { Header: 'Amount', accessor: 'feeAmount', width: '10%' },
  { Header: 'Due Date', accessor: 'dueDate', width: '10%' },
  { Header: 'Actions', accessor: 'actions', width: '10%' }
];

export const DEFAULT_PLACEHOLDER = '--';

export const groupStatusChangeConfirmModalInfo = (
  status: 'lite' | 'cofee'
): Record<string, any> => ({
  deactivate: {
    title: 'Are you sure you want to deactivate this group?',
    description: `Payment ${
      status === 'lite' ? 'request' : 'links'
    } will not be sent to members of deactivated group`
  },
  activate: {
    title: 'Do you want to activate this group?',
    description: `Members of this group will start receiving payment ${
      status === 'lite' ? 'request' : 'links'
    }`
  },
  activateWithEndDateClear: {
    title: 'Do you want to activate this group?',
    description:
      'The group has expired, reactivating the group will clear the end date.'
  },
  deactivateBeforeEndDate: {
    title: 'Are you sure you want to deactivate this group?',
    description: `The group has an upcoming end date, no payment ${
      status === 'lite' ? 'request' : 'links'
    } will be sent after deactivation.`
  }
});

export const memberStatusChangeConfirmModalInfo = (
  status: 'lite' | 'cofee'
): Record<string, any> => ({
  deactivate: {
    title: 'Are you sure you want to deactivate',
    description: `Deactivated members will not receive payment ${
      status === 'lite' ? 'request' : 'links'
    } for the coming months`
  },
  activate: {
    title: 'Are you sure you want to activate',
    description: `Active members will receive payment ${
      status === 'lite' ? 'request' : 'links'
    } for the coming months`
  }
});

export const groupDetailsInitailState: GroupDetailsType = {
  id: '',
  name: '',
  amount: '',
  frequency: '',
  interval_frequency: 0,
  is_recurring: false,
  is_active: false,
  member_count: 0,
  next_due_at: '',
  image_url: '',
  created_at: '',
  payment_list: [],
  collection_started_at: '',
  end_date: '',
  is_attendance_tracking_enabled: false,
  is_send_attendance_alert_enabled: false,
  work_days: [],
  is_fee_configured: false
};

export const groupDetailsTabs: TabPill[] = [
  { id: 1, label: 'Overview' },
  { id: 2, label: 'Members' },
  { id: 3, label: 'Attendance' },
  { id: 4, label: 'Transactions' }
];

export const selectedMemberInitialState = {
  created_at: '',
  fee_amount: '',
  group_member_id: 0,
  is_active: false,
  is_payment_link_sent: false,
  member_id: '',
  member_image_url: '',
  name: '',
  next_due_at: '',
  payment_due: '',
  phone: '',
  secondary_mobile: '',
  isFeeCategoryEnabled: '',
  feeCategories: [],
  id: '',
  primary_country_code: '',
  secondary_country_code: '',
  email: ''
};

export const nextOrCurrentMonthOptions = [
  {
    key: 'next',
    value: 'Next month'
  },
  {
    key: 'current',
    value: 'Current month'
  }
];

export const nextOrCurrentWeekOptions = [
  {
    key: 'next',
    value: 'Next week'
  },
  {
    key: 'current',
    value: 'Current week'
  }
];

export const editMemberInitialState = {
  name: '',
  primaryNumber: '',
  altNumber: '',
  amount: '',
  dueDate: '',
  id: '',
  is_payment_link_sent: false
};

export const groupFormDataDefaultValue: FormDataType = {
  groupPhoto: '',
  groupName: '',
  activationDate: '',
  intervalFrequency: '',
  amount: '',
  paymentInstallment: false,
  paymentCollectionType: '',
  endDate: '',
  isAttandanceTrackingEnabled: false,
  isSendAttendanceAlertEnabled: false,
  workingDays: []
};

export const groupsCrumbsPath = [
  { name: 'Groups', path: getGroupsHome() },
  { name: 'New Group', path: GROUPS.ADD_NEW_GROUP }
];

export enum GroupTransactionsTabOptionsEnum {
  recent = 'all',
  paid = 'paid',
  pending = 'pending'
}

export const groupTransactionsTabOptions = [
  { key: GroupTransactionsTabOptionsEnum.recent, label: 'Recent' },
  { key: GroupTransactionsTabOptionsEnum.paid, label: 'Paid' },
  { key: GroupTransactionsTabOptionsEnum.pending, label: 'Pending' }
];

export const groupAllTransactionsTabTableColumn = [
  { Header: 'Name', accessor: 'name', width: '20%' },
  { Header: 'Due From', accessor: 'dueFrom', width: '18%' },
  { Header: 'Paid On', accessor: 'paidOn', width: '15%' },
  { Header: 'Settled On', accessor: 'settledOn', width: '20%' },
  { Header: 'Amount', accessor: 'amount', width: '15%' },
  { Header: 'Actions', accessor: 'actions' }
];

export const groupPaidTransactionsTabTableColumn = [
  { Header: 'Name', accessor: 'name', width: '20%' },
  { Header: 'Due From', accessor: 'dueFrom', width: '18%' },
  { Header: 'Paid On', accessor: 'paidOn', width: '18%' },
  { Header: 'Settled On', accessor: 'settledOn', width: '20%' },
  { Header: 'Amount', accessor: 'amount', width: '15%' },
  { Header: 'Actions', accessor: 'actions' }
];

export const groupPendingTransactionsTabTableColumn = [
  { Header: 'Name', accessor: 'name', width: '30%' },
  { Header: 'Due From', accessor: 'dueFrom', width: '25%' },
  { Header: 'Amount', accessor: 'amount', width: '25%' },
  { Header: 'Actions', accessor: 'actions' }
];
