import { Icon, AmountInput } from '@components';

import theme from '../../../themes/colors';

interface AmountChangeParams {
  value: string;
  index: number;
  categoryName: string;
}

interface FeeConfigInputProps {
  feeCategories: { category: string; amount: string }[];
  onCategoryAmountChange: (params: AmountChangeParams) => void;
  memberActionType: 'add' | 'edit';
}

const FeeConfigInput = ({
  feeCategories,
  onCategoryAmountChange,
  memberActionType
}: FeeConfigInputProps) => {
  const nonZeroItems = feeCategories.filter(
    ({ amount }) => Number(amount) !== 0
  );

  return (
    <div>
      <div className="mt-4 flex w-full gap-6">
        <div className="w-1/2 text-base text-grey">Category</div>
        <div className="w-1/2 text-base text-grey">Amount</div>
      </div>
      {feeCategories.map(({ category, amount }, index) => (
        <div key={category} className="mt-3 flex w-full gap-6">
          <div className="w-1/2 rounded-lg bg-grey10 px-4 py-3 text-base text-content">
            {category}
          </div>
          <div className="w-1/2 text-base text-content">
            <AmountInput
              value={amount}
              onAmountChange={(newValue) => {
                if (
                  memberActionType === 'edit' &&
                  Number(newValue) === 0 &&
                  nonZeroItems.length === 1 &&
                  Number(amount) !== 0
                ) {
                  return;
                }
                onCategoryAmountChange({
                  index,
                  value: newValue,
                  categoryName: category
                });
              }}
              allowZero
            />
          </div>
        </div>
      ))}
      <div className="flex items-center gap-1 py-4 text-base text-grey">
        <Icon name="alert-circle" stroke={theme.subText} />
        If a fee doesn&apos;t apply, enter &quot;₹0&quot;
      </div>
    </div>
  );
};

export default FeeConfigInput;
