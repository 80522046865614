import { SUBSCRIPTIONS } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const SubscriptionPlan = lazy(
  () => import('@features/subscription/pages/SubscriptionPlan')
);
const ChoosePlan = lazy(
  () => import('@features/subscription/pages/ChoosePlan')
);
export const SubscriptionRoutes: RouteObject[] = [
  {
    path: SUBSCRIPTIONS.PLAN_DETAILS,
    element: <SubscriptionPlan />
  },
  {
    path: SUBSCRIPTIONS.CHOOSE_PLAN,
    element: <ChoosePlan />
  }
];
export default SubscriptionRoutes;
