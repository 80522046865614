import { Button, Icon } from '@components';

import Modal from './Modal';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message?: string;
  iconName?: string;
  ModalIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  cancelButtonStyle?: string;
  confirmButtonStyle?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  className?: string;
  isConfirmButtonLoading?: boolean;
  children?: any;
  wrapperClass?: string;
  isConfirmButtonDisabled?: boolean;
  buttonIds?: { confirmButton?: string; cancelButton?: string };
  confirmButtonLabelStyle?: string;
}

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  iconName,
  ModalIcon,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  cancelButtonStyle = '',
  confirmButtonStyle = 'text-primary',
  handleConfirm,
  handleCancel,
  className,
  isConfirmButtonLoading,
  children,
  wrapperClass,
  isConfirmButtonDisabled,
  buttonIds,
  confirmButtonLabelStyle
}: ConfirmationModalProps) => (
  <Modal
    isOpen={isOpen}
    onCancel={handleCancel}
    className={`!rounded-xl ${wrapperClass}`}
  >
    <div className={`p-6 ${className && className}`}>
      {iconName && (
        <div className="mb-4 flex items-center justify-center">
          <Icon name={iconName} size="extralarge" />
        </div>
      )}
      {ModalIcon && (
        <div className="mb-4 flex items-center justify-center">
          <ModalIcon className="size-8" />
        </div>
      )}
      <div className="mb-3 flex justify-center whitespace-pre-wrap break-normal text-center text-lg font-medium text-content">
        {title}
      </div>
      {message && (
        <div className="flex justify-center whitespace-pre-wrap break-words text-center text-sm font-normal text-grey">
          {message}
        </div>
      )}
      {children && children}
      <div className="mt-6 flex justify-between">
        <Button
          id={buttonIds?.cancelButton}
          handleClick={handleCancel}
          variant="transparent"
          size="none"
          className={`ml-6 text-base font-medium ${cancelButtonStyle || ''}`}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          id={buttonIds?.confirmButton}
          handleClick={handleConfirm}
          variant="transparent"
          size="none"
          className={`mr-6 text-base font-medium ${confirmButtonStyle || ''}`}
          loading={isConfirmButtonLoading || false}
          label={confirmButtonLabel}
          labelStyle={confirmButtonLabelStyle}
          disabled={isConfirmButtonDisabled}
        />
      </div>
    </div>
  </Modal>
);

export default ConfirmationModal;
