import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { QUICK_COLLECT } from '@constants/routes';

const QUICK_COLLECT_CREATE = lazy(
  () =>
    import(
      '@features/quick-collect/pages/create-link/CreateQuickCollectPayment'
    )
);

const QUICK_COLLECT_SUCCESS = lazy(
  () => import('@features/quick-collect/pages/success/QuickCollectSuccess')
);

export const QuickCollectRoutes: RouteObject[] = [
  {
    path: QUICK_COLLECT.CREATE_LINK,
    element: <QUICK_COLLECT_CREATE />
  },
  {
    path: QUICK_COLLECT.SUCCESS,
    element: <QUICK_COLLECT_SUCCESS />
  }
];

export default QuickCollectRoutes;
