import { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { PaymentCollectionType } from '@features/group-management/constants';
import { CardProps } from '@types';

import './styles.css';
import colors from '../../../../themes/colors';

const OnceCard = ({
  handleActivationDate,
  handleIntervalFrequency,
  selectedDate,
  type,
  minDate
}: CardProps) => {
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    if (!selectedDate || type !== PaymentCollectionType.ONCE) {
      handleIntervalFrequency('');
    }
    if (selectedDate) setDate(selectedDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    handleActivationDate(date || new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Calendar
      className="!text-sm !text-content"
      date={date}
      onChange={(modifiedDate) => setDate(modifiedDate)}
      color={`${colors.primary}`}
      {...(minDate && { minDate })}
    />
  );
};

export default OnceCard;
