import { useRef, useState } from 'react';

import useOnClickOutside from '@utils/hooks';
import { DropdownOption } from 'types/dropdown';

import Chip from '../chip/Chip';
import Tooltip from '../tooltip/Tooltip';
import { PlusNChipListProps } from './types';
import './styles.scss';

const PlusNChipList = (props: PlusNChipListProps) => {
  const ref = useRef(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const {
    displayChipsCount = 2,
    selectedOptions,
    handleOnRemove,
    isCloseIconRequired = true,
    plusMoreClickRequired = false,
    chipClassName,
    tooltipChipClassName,
    plusNChipBgColor = 'bg-white',
    id,
    tooltipColor = 'white',
    className,
    onClickPlusMoreText
  } = props;

  const displayChipList = selectedOptions.slice(0, displayChipsCount);
  const restOfTheChipList = selectedOptions.slice(displayChipsCount);

  const tooltipLabels = restOfTheChipList.map((option) => option.label);

  const handleChipRemove = (
    e: React.MouseEvent<HTMLElement>,
    chipElement: DropdownOption
  ) => {
    e.stopPropagation();
    if (handleOnRemove) handleOnRemove(chipElement);
  };

  const handlePlusMoreClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
    onClickPlusMoreText?.();
  };

  const renderPlusMoreText = () => (
    <div
      className={`ml-0.5 flex h-7 cursor-pointer items-center rounded-full  px-2 py-0.5 text-xs font-medium text-primary hover:underline ${plusNChipBgColor}`}
    >
      +{selectedOptions.length - displayChipsCount} More
    </div>
  );

  useOnClickOutside(ref, () => {
    setShowTooltip(false);
  });

  return (
    <div className={`flex items-center ${className}`}>
      {displayChipList.map((item) => (
        <Chip
          label={item.label || ''}
          className={`mr-0.5 ${chipClassName || ''}`}
          onRemove={(e) => handleChipRemove(e, item)}
          key={item.value}
          isCloseIconRequired={isCloseIconRequired}
        />
      ))}
      {!plusMoreClickRequired
        ? // displays tooltip on hover
          selectedOptions.length > displayChipsCount && (
            <div data-tooltip-id={`plus-more-${id}`}>
              {renderPlusMoreText()}
              <Tooltip
                id={`plus-more-${id}`}
                className="!max-w-400 shadow-tooltipShadow"
                variant={tooltipColor === 'white' ? 'light' : 'dark'}
              >
                <div className="flex flex-wrap gap-2">
                  {tooltipLabels?.map((item) => (
                    <Chip label={item || ''} className="bg-grey10" key={item} />
                  ))}
                </div>
              </Tooltip>
            </div>
          )
        : // displays tooltip on click
          selectedOptions.length > displayChipsCount && (
            <div
              role="presentation"
              onClick={handlePlusMoreClick}
              className="relative z-10"
              ref={ref}
              data-tooltip-id={`plus-more-click-${id}`}
            >
              {renderPlusMoreText()}
              <Tooltip
                id={`plus-more-click-${id}`}
                className="w-max max-w-500 shadow-tooltipShadow"
                place="bottom"
                showTooltip={showTooltip}
                clickable
                variant="light"
              >
                <div className="flex flex-row flex-wrap gap-2 p-3">
                  {restOfTheChipList.map((item) => (
                    <Chip
                      label={item.label || ''}
                      key={item.value}
                      onRemove={(e) => handleChipRemove(e, item)}
                      className={tooltipChipClassName || chipClassName || ''}
                    />
                  ))}
                </div>
              </Tooltip>
            </div>
          )}
    </div>
  );
};

export default PlusNChipList;
