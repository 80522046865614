import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Toast } from '@components';
import { ToastType } from 'types/toast';

const Notification = () => {
  const { alerts } = useSelector((state: any) => state.notification);

  const [alert, setAlert] = useState({
    type: '',
    message: '',
    onClose: () => {}
  });
  const [show, setShow] = useState(false);

  const hideToast = () => setShow(false);

  const showToast = () => setShow(true);

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      showToast();
      setTimeout(() => {
        hideToast();
      }, 3000);
    }
  }, [alerts]);

  return show ? (
    <div className="fixed inset-x-0 top-8 z-[60] flex justify-center">
      <Toast
        type={alert.type as ToastType}
        message={alert.message}
        onClose={hideToast}
      />
    </div>
  ) : null;
};

export default Notification;
