import {
  changeDayInGivenDate,
  getCurrentMonthDate,
  getDateOfCurrentWeekDay,
  getDateOfNextWeek,
  getNextMonthDate,
  isCurrentOrFutureDayOfWeek,
  isFutureDay,
  isFutureDayOfWeek,
  isFutureOrCurrentDay
} from '@utils/date';

export const handleMonthDateSelect = (item: string, date?: Date) => {
  const startDate = date || new Date();
  let nextDueDate;
  if (isFutureOrCurrentDay(item, startDate)) {
    nextDueDate = getCurrentMonthDate(item, startDate);
  } else {
    nextDueDate = getNextMonthDate(item, startDate);
  }
  const isFutureDate = isFutureDay(item, startDate);
  return { nextDueDate: new Date(nextDueDate), isFutureDate };
};

export const handleWeekDateSelect = (id: number, date?: Date) => {
  const startDate = date || new Date();
  let nextDueDate;
  if (isCurrentOrFutureDayOfWeek(id, startDate)) {
    nextDueDate = getDateOfCurrentWeekDay(id, startDate);
  } else {
    nextDueDate = getDateOfNextWeek(id, startDate);
  }
  const isFutureDate = isFutureDayOfWeek(id, startDate);
  return { nextDueDate: new Date(nextDueDate), isFutureDate };
};

export const handleTermDateSelect = ({
  nextDueDate,
  item,
  date
}: {
  nextDueDate?: string;
  item: string;
  date?: Date;
}) => {
  let modifiedDueDate;
  const startDate = date || new Date();
  const isDueMonth = nextDueDate
    ? new Date(nextDueDate).getMonth() === new Date().getMonth()
    : false;
  if (nextDueDate && !isDueMonth) {
    modifiedDueDate = changeDayInGivenDate(new Date(nextDueDate), item);
  } else if (isFutureOrCurrentDay(item, startDate)) {
    modifiedDueDate = getCurrentMonthDate(item, startDate);
  } else {
    modifiedDueDate = getNextMonthDate(item, startDate);
  }
  return new Date(modifiedDueDate);
};
