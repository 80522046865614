import { REPORTS } from '@constants/routes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Reports = lazy(
  () => import('@features/reports/pages/reports-home/Reports')
);

export const ReportsRoutes: RouteObject = {
  path: REPORTS,
  element: <Reports />
};

export default ReportsRoutes;
