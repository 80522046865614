import { useDispatch } from 'react-redux';
import { createAlert } from '@components/notification/NotificationSlice';

const useNotify = () => {
  const dispatch = useDispatch();
  const createSuccessAlert = (message: string) =>
    dispatch(
      createAlert({
        alert: {
          message,
          type: 'success'
        }
      })
    );
  const createErrorAlert = (message?: string) =>
    dispatch(
      createAlert({
        alert: {
          message: message || 'Something went wrong. Please try again later.',
          type: 'error'
        }
      })
    );
  const createWarningAlert = (message?: string) =>
    dispatch(
      createAlert({
        alert: {
          message: message || 'Something went wrong. Please try again later.',
          type: 'warning'
        }
      })
    );

  return { createSuccessAlert, createErrorAlert, createWarningAlert };
};

export default useNotify;
