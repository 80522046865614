import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse } from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubscription: builder.query<
      HTTPResponse<any>,
      { organizationId: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.SUBSCRIPTION.GET_CURRENT_SUBSCRIPTION(
          req.organizationId
        )}`,
        method: 'GET'
      })
    }),
    getSubscriptionPlans: builder.query<
      HTTPResponse<any>,
      { organizationId: string }
    >({
      query: (req) => ({
        url: `${END_POINTS.SUBSCRIPTION.GET_SUBSCRIPTION_PLANS(
          req.organizationId
        )}`,
        method: 'GET'
      })
    }),
    selectSubscription: builder.mutation<
      HTTPResponse<any>,
      {
        organizationId: string;
        planId: number;
        isMonthly: boolean;
      }
    >({
      query: (req) => ({
        url: `${END_POINTS.SUBSCRIPTION.SELECT_SUBSCRIPTION(
          req.organizationId
        )}`,
        method: 'POST',
        body: {
          plan_id: req.planId,
          is_monthly: req.isMonthly
        }
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetSubscriptionPlansQuery,
  useSelectSubscriptionMutation
} = extendedBaseQuery;
