import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Logo from '@assets/images/logo.svg';
import CoFeeIcon from '@assets/images/cofee-popins.svg';
import {
  HELP_ITEM,
  INDIVIDUAL_MENU_ITEMS,
  ORG_MENU_ITEMS,
  groupsTabPathPattern
} from '@constants/sideMenu';
import { Modal, SidebarItem } from '@components';
import useCheckPermission from '@hooks/checkPermission';
import useOnClickOutside from '@utils/hooks';
import { AccountType, OrgStatus } from '@constants/generic';
import { getGroupsHome } from '@utils/generic';

import Profile from './Profile';
import EditOrganization from './EditOrganization';

const SideNavBar = () => {
  const location = useLocation();

  const ref = useRef(null);
  const { currentOrg, accountType } = useSelector((state: any) => state.user);
  const { rolesAndPermissions } = useSelector((state: any) => state.user);

  const [isSidebarOpen, setSideBarOpen] = useState<boolean>(false);
  const [showOrganizationDetailsModal, setShowOrganizationDetailsModal] =
    useState<boolean>(false);

  const showModal =
    showOrganizationDetailsModal &&
    currentOrg?.type === AccountType.ORGANIZATION;

  const onCancelModal = () => setShowOrganizationDetailsModal(false);

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
  });

  const { checkPermission } = useCheckPermission();

  const openSidebar = () => {
    setSideBarOpen(true);
  };

  const closeSidebar = () => {
    setSideBarOpen(false);
  };

  const menuItems =
    accountType.type === AccountType.ORGANIZATION
      ? ORG_MENU_ITEMS
      : INDIVIDUAL_MENU_ITEMS;

  const permittedSideNavItems = menuItems?.filter((navItem) =>
    navItem?.permissions.some((permission) => checkPermission([permission]))
  );

  const renderSidebarElement = () =>
    permittedSideNavItems.map((item: any) => {
      // TO DO - Refactor this logic
      if (
        (currentOrg?.status === OrgStatus.VERIFICATION_PENDING ||
          currentOrg?.status === OrgStatus.VERIFICATION_SUBMITTED) &&
        item.id === 'subscriptions'
      )
        return undefined;
      const { id, route } = item;
      let isCurrentRoute = false;
      let path = route;
      if (id === 'groups') {
        path = getGroupsHome();
        isCurrentRoute = groupsTabPathPattern.test(location.pathname);
      } else if (!groupsTabPathPattern.test(location.pathname)) {
        isCurrentRoute = location.pathname.startsWith(route);
      }
      return (
        <SidebarItem
          item={item}
          path={path}
          isCurrentRoute={isCurrentRoute}
          isSidebarOpen={isSidebarOpen}
          key={id}
        />
      );
    });

  return (
    <>
      {!rolesAndPermissions?.permissions?.length ? (
        <div className="group absolute left-0 top-0 z-50 flex h-full w-22 flex-col justify-between bg-content shadow-sm transition-all duration-300 ease-in-out" />
      ) : (
        <div
          onMouseEnter={openSidebar}
          onMouseLeave={closeSidebar}
          className={`no-scrollbar group absolute left-0 top-0 z-50 flex h-full flex-col justify-between overflow-auto bg-content shadow-sm transition-all duration-300 ease-in-out ${
            isSidebarOpen ? 'w-64' : 'w-22'
          }`}
        >
          <div>
            <div className="flex items-center gap-2 px-6 py-4">
              <img src={Logo} alt="CoFee logo" className="size-8" />
              <img
                src={CoFeeIcon}
                alt="CoFee"
                className={`${isSidebarOpen ? '' : 'hidden'}`}
              />
            </div>
            <div className="mx-4 border-t border-t-borderGrey">
              <div className="mt-6 flex flex-col gap-3 py-3">
                {renderSidebarElement()}
              </div>
              <div className="mt-8 h-1 border-t-2 border-dotted border-grey pb-8" />
              <div>
                <SidebarItem
                  item={HELP_ITEM}
                  isCurrentRoute={location.pathname.startsWith(HELP_ITEM.route)}
                  isSidebarOpen={isSidebarOpen}
                />
              </div>
            </div>
          </div>
          <Profile
            isSidebarOpen={isSidebarOpen}
            currentOrg={currentOrg}
            onClickOrganizationEdit={() =>
              setShowOrganizationDetailsModal(true)
            }
          />
        </div>
      )}

      {showModal && (
        <Modal isOpen={showModal} onCancel={onCancelModal} isCloseIconRequired>
          <EditOrganization
            imageUrl={currentOrg?.image_url || ''}
            currentOrgName={currentOrg?.name || ''}
            currentOrgId={currentOrg?.id || ''}
            handleSave={onCancelModal}
          />
        </Modal>
      )}
    </>
  );
};

export default SideNavBar;
