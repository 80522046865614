import { AUTH, PAGE_NOT_FOUND } from '../../constants/routes';

enum Event {
  // Login Page

  LOGIN_MOBILE_NUMBER_SUCCESS = 'Login mobile number success',
  LOGIN_MOBILE_NUMBER_ERROR = 'Login mobile number error',

  // OTP page

  LOGIN_MOBILE_NUMBER_EDIT_CLICKED = 'Login mobile number edit clicked',
  SUBMIT_OTP_SUCCESS = 'Submit otp success',
  SUBMIT_OTP_ERROR = 'Submit otp error',
  RESEND_OTP_CLICKED = 'Resend otp clicked',
  RESEND_OTP_SUCCESS = 'Resend otp success',

  // Sign up

  CREATE_ACCOUNT_CLICKED = 'Create account clicked',
  CREATE_ACCOUNT_CONTINUE_CLICKED = 'Create account continue clicked',
  DOCUMENT_UPLOAD_CONTINUE_CLICKED = 'Document upload continue clicked',
  DOCUMENT_UPLOAD_SUCCESS = 'Document upload success',
  DOCUMENT_UPLOAD_ERROR = 'Document upload error',
  DOCUMENT_REUPLOAD_SUCCESS = 'Document reupload success',
  DOCUMENT_REUPLOAD_ERROR = 'Document reupload error',
  CREATE_BRANCH_CONTINUE_CLICKED = 'Create branch continue clicked',
  BRANCH_IMAGE_UPLOAD_SUCCESS = 'Branch image upload success',
  BRANCH_IMAGE_UPLOAD_ERROR = 'Branch image upload error',
  UPI_VERIFICATION_SUCCESS = 'Upi verification success',
  UPI_VERIFICATION_ERROR = 'Upi verification error',
  BANK_ACCOUNT_VERIFICATION_SUCCESS = 'Bank account verification success',
  BANK_ACCOUNT_VERIFICATION_ERROR = 'Bank account verification error',
  SETTLEMENT_SELECTION_PAGE_BUTTON_CLICKED = 'Settlement selection page button clicked',
  ONBOARD_SUCCESS = 'Onboard success',
  ONBOARD_ERROR = 'Onboard error',

  SIDEBAR_TAB_CLICKED = 'Sidebar tab clicked',

  // Branches

  BRANCH_CARD_CLICKED = 'Branch card clicked',
  BRANCH_EDIT_CLICKED = 'Branch edit clicked',
  ADD_NEW_BRANCH_CLICKED = 'Add new branch clicked',
  BRANCH_CREATION_SUCCESS = 'Branch creation success',
  BRANCH_CREATION_ERROR = 'Branch creation error',
  BRANCH_UPDATION_SUCCESS = 'Branch updation success',
  BRANCH_UPDATION_ERROR = 'Branch updation error',
  BRANCH_CREATION_CANCEL_CLICKED = 'Branch creation cancel clicked',
  SETTLEMENT_ACCOUNT_ADD_NEW_CLICKED = 'Settlement account ADD NEW clicked',
  SETTLEMENT_ACCOUNT_UPDATE_CLICKED = 'Settlement account UPDATE clicked',

  // Groups

  ADD_NEW_GROUP_CLICKED = 'Add new group clicked',
  GROUP_IMAGE_UPLOAD_SUCCESS = 'Group image upload success',
  GROUP_IMAGE_UPLOAD_ERROR = 'Group image upload error',
  GROUP_CREATION_SUCCESS = 'Group creation success',
  GROUP_CREATION_ERROR = 'Group creation error',
  GROUP_CREATION_CANCEL_CLICKED = 'Group creation cancel clicked',

  GROUP_CARD_CLICKED = 'Group card clicked',
  GROUP_EDIT_ICON_CLICKED = 'Group edit icon clicked',
  GROUP_INFO_TAB_CHANGED = 'Group Info: tab changed',

  GROUP_UPDATION_SUCCESS = 'Group updation success',
  GROUP_UPDATION_ERROR = 'Group updation error',
  REPORT_DOWNLOAD_SUCCESS = 'Report download success',
  REPORT_DOWNLOAD_ERROR = 'Report download error',
  MEMBER_DELETE_SUCCESS = 'Member delete success',
  MEMBER_DELETE_ERROR = 'Member delete error',
  MEMBER_UPDATION_SUCCESS = 'Member updation success',
  MEMBER_UPDATION_ERROR = 'Member updation error',
  MEMBER_ADDITION_TO_EXISTING_GROUP_SUCCESS = 'Add member to existing group success',
  MEMBER_ADDITION_TO_EXISTING_GROUP_ERROR = 'Add member to existing group error',

  // Team Management

  INVITATION_SEND_SUCCESS = 'Invitation send success',
  INVITATION_SEND_ERROR = 'Invitation send error',
  INVITATION_DELETE_SUCCESS = 'Invitation delete success',
  INVITATION_DELETE_ERROR = 'Invitation delete error',
  USER_DELETE_SUCCESS = 'User delete success',
  USER_DELETE_ERROR = 'User delete error',
  USER_UPDATION_SUCCESS = 'User updation success',
  USER_UPDATION_ERROR = 'User updation error',

  TM_TAB_CHANGED = 'TM: tab changed',
  INVITE_MEMBER_BUTTON_CLICKED = 'Invite Member button clicked',

  // Accept/Reject Invitations

  INVITATION_POPUP_VIEWED = 'Invitation popup viewed',
  INVITATION_ACCEPTED_SUCCESS = 'Invitation accepted success',
  INVITATION_ACCEPTED_ERROR = 'Invitation accepted error',
  INVITATION_DECLINE_SUCCESS = 'Invitation decline success',
  INVITATION_DECLINE_ERROR = 'Invitation decline error',

  // Upgrade to organization

  UPGRADE_TO_ORGANIZATION_POPUP_VIEWED = 'Upgrade to organization popup viewed',
  UPGRADE_TO_ORGANIZATION_CONFIRM_BUTTON_CLICKED = 'Upgrade to organization confirm button clicked',
  UPGRADE_TO_ORGANIZATION_CANCEL_BUTTON_CLICKED = 'Upgrade to organization cancel button clicked',

  // Upgrade Lite

  UPGRADE_LITE_POPUP_VIEWED = 'Upgrade lite popup viewed',
  UPGRADE_LITE_CONFIRM_BUTTON_CLICKED = 'Upgrade lite confirm button clicked',
  UPGRADE_LITE_CANCEL_BUTTON_CLICKED = 'Upgrade lite cancel button clicked',
  UPGRADE_LITE_SUCCESS = 'Upgrade lite success',
  UPGRADE_LITE_ERROR = 'Upgrade lite error',
  // Switch account

  SWITCH_ACCOUNT_CLICKED = 'Switch account clicked',
  SWITCH_ACCOUNT_CONTINUE_BUTTON_CLICKED = 'Switch account continue button clicked',
  SWITCH_ACCOUNT_CANCEL_BUTTON_CLICKED = 'Switch account cancel button clicked',

  // Organzation rejected

  CONTACT_SUPPORT_CLICKED = 'Contact support clicked',

  // Organization Selection

  ORGANIZATION_SELECTION_CONTINUE_CLICKED = 'Organization Selection continue clicked',

  // Logout

  LOGOUT_CLICKED = 'Logout clicked',
  LOGOUT_CONFIRM_BUTTON_CLICKED = 'Logout confirm button clicked',
  LOGOUT_CANCEL_BUTTON_CLICKED = 'Logout cancel button clicked',
  LOGOUT_SUCCESS = 'Logout success',
  LOGOUT_ERROR = 'Logout error',

  // Upload

  IMAGE_UPLOAD_ERROR = 'Image upload error',
  FILE_UPLOAD_ERROR = 'File upload error',

  // Help page

  HELP_FEEDBACK_SUCCESS = 'Feedback send success',
  HELP_FEEDBACK_ERROR = 'Feedback send error',

  // Attendance

  ATTENDANCE_TRACKING_ENABLED = 'Attendance tracking enabled',
  ATTENDANCE_DAYS_CLICKED = 'Attendance days clicked',
  ATTENDANCE_SMS_ALERT_CLICKED = 'Attendance SMS alert clicked',
  ATTENDANCE_SMS_ALERT_CONFIRM_CLICKED = 'Attendance SMS alert confirm button clicked',
  ATTENDANCE_EDIT_BUTTON_CLICKED = 'Attendance edit button clicked',
  ATTENDANCE_SUBMIT_BUTTON_CLICKED = 'Attendance submit button clicked',
  ATTENDANCE_STATUS_CHANGE_BUTTON_CLICKED = 'Attendance status change button clicked',
  ATTENDANCE_SUBMIT_CONFIRM_MODAL_BUTTON_CLICKED = 'Attendance submit confirm modal button clicked',
  ATTENDANCE_REPORT_DOWNLOAD_BUTTON_CLICKED = 'Attendance report download button clicked',
  ATTENDANCE_ENABLE_BUTTON_CLICKED = 'Attendance enable button clicked while attendance is disabled',
  ATTENDANCE_MARK_HOLIDAY_CHECKBOX_CLICKED = 'Mark holiday checkbox clicked',
  ATTENDANCE_MARK_HOLIDAY_MODAL_CONFIRM_CLICKED = 'Attendance mark holiday modal confirm clicked',
  ATTENDANCE_UNMARK_HOLIDAY_BUTTON_CLICKED = 'Unmark holiday button clicked',
  ATTENDANCE_UNMARK_HOLIDAY_MODAL_CONFIRM_CLICKED = 'Unmark holiday modal confirm clicked',

  // Bank Admin

  BANK_ADMIN_TAB_CHANGED = 'Bank admin tab changed',

  // Transaction Page

  TRANSACTION_TAB_CHANGED = 'Transaction page: tab changed',
  TRANSACTION_DETAILS_CLICKED = 'Transaction details row clicked',
  TRANSACTION_PAYMENT_DETAILS_DOWNLOAD_BUTTON_CLICKED = 'Transaction payment details download button clicked',
  TRANSACTION_MARK_AS_PAID_CLICKED = 'Transaction mark as paid button clicked',
  TRANSACTION_DISABLED_BUTTON_CLICKED = 'Transaction disabled button clicked',
  TRANSACTION_DISABLE_MODAL_CONFIRM_CLICKED = 'Transaction disable modal confirm clicked',
  TRANSACTION_SEND_REMINDER_BUTTON_CLICKED = 'Transaction send reminder button clicked',
  TRANSACTION_SEND_REMINDER_MODAL_CONFIRM_CLICKED = 'Transaction send reminder modal confirm clicked',
  TRANSACTION_MARK_AS_PAID_MODAL_CONFIRM_CLICKED = 'Transaction mark as paid modal confirm clicked',
  TRANSACTION_PAYMENT_LINK_COPIED = 'Transaction payment link copied',
  TRANSACTION_DETAILS_SELECTED_BRANCH_CHANGE = 'Transaction details selected branch change',
  TRANSACTION_ENABLE_PARTIAL_PAYMENT_CONFIRM_CLICKED = 'Transaction enable partial payment confirm clicked',
  TRANSACTION_ENABLE_PARTIAL_PAYMENT_CLICKED = 'Transaction enable partial payment button clicked',

  // Dashboard Page

  DASHBOARD_SELECTED_DATERANGE = 'Dashboard selected date range',
  DASHBOARD_SELECTED_BRANCH = 'Dashboard selected branch',
  DASHBOARD_SELECTED_BRANCH_FOR_BRANCH_REVENUE = 'Dashboard selected branches for branch revenue graph',

  // Reports Page

  DOWNLOAD_GST_REPORT_CLICKED = 'Download GST report clicked',
  DOWNLOAD_GST_REPORT_ERROR = 'Download GST report error',
  DOWNLOAD_SETTLEMENT_REPORT_CLICKED = 'Download Settlement report clicked',
  DOWNLOAD_SETTLEMENT_REPORT_ERROR = 'Download Settlement report error',
  DOWNLOAD_CUSTOM_REPORT_CLICKED = 'Download Custom report clicked',
  DOWNLOAD_CUSTOM_REPORT_ERROR = 'Download Custom report error',
  // Fee Config

  ADD_BRANCH_ACCOUNT_IN_BRANCH_CREATE_OR_EDIT = 'Add Bank Account in Branch create or edit',
  ACCOUNT_LINKED_WITH_PAN_ADDED = 'Account linked with PAN added',
  ERROR_IN_ADDING_ACCOUNT_LINKED_WITH_PAN = 'Error in adding account linked with PAN',
  ACCOUNT_LINKED_WITH_NEW_PAN_ADDED = 'Account linked with new PAN added',
  ERROR_IN_ADDING_NEW_PAN_FOR_BANK_ACCOUNT = 'Error in adding new pan for bank account',
  ERROR_IN_ADDING_ACCOUNT_LINKED_WITH_NEW_PAN = 'Error in adding account linked with new PAN',
  CONFIRMATION_OF_NEW_PAN_LINKED_WITH_ORGANIZATION = 'Confirmation of new PAN linked with organisation',
  FEE_CATEGORIES_ENABLED_OR_DISABLED = 'Fee categories enabled or disabled',
  BRANCH_WITH_FEE_CATEGORY_CREATED_SUCCESSFULLY = 'Branch with fee category created successfully',
  BRANCH_WITH_FEE_CATEGORY_UPDATED_SUCCESSFULLY = 'Branch with fee category updated successfully',
  ERROR_IN_CREATING_BRANCH_WITH_FEE_CATEGORY = 'Error in creating branch with fee category',
  ERROR_IN_UPDATING_BRANCH_WITH_FEE_CATEGORY = 'Error in updating branch with fee category',
  FEE_CATEGORY_ENABLED_OR_DISABLED_IN_ADD_OR_EDIT_GROUP = 'Fee category enabled or disabled in add or edit group',
  FEE_AMOUNTS_IN_FEE_CATEGORY_OF_GROUP_IS_EDITED = 'Fee amounts in fee category of group is edited',
  GROUP_WITH_FEE_CATEGORY_CREATED = 'Group with fee category created',
  GROUP_WITH_FEE_CATEGORY_EDITED = 'Group with fee category edited',
  ERROR_IN_EDITING_GROUP_WITH_FEE_CATEGORY = 'Error in editing group with fee category',

  // Rapid Onboard

  SAME_NAME_ON_RECEIPT = 'Same name on receipt',
  DISPLAY_NAME_ON_RECEIPT = 'Display name on receipt',
  ADD_KYC_INFO_CLICKED = 'Add KYC info clicked',
  KYC_DETAILS_VERIFIED = 'KYC Details verified',
  KYC_VERIFICATION_ERROR = 'KYC Verification error',
  CONTINUE_IN_COMPLETE_KYC_POPUP_CLICKED = 'Continue in complete KYC pop up clicked',
  CANCEL_IN_COMPLETE_KYC_POPUP_CLICKED = 'Cancel in complete KYC pop up clicked',

  // Quick collect
  ERROR_IN_CREATING_QUICK_COLLECT_LINK = 'Error in creating quick collect link',
  QUICK_COLLECT_SUCCESS = 'Quick collect link creation success',
  QUICK_COLLECT_INVALID_CSV = 'Quick collect invalid csv',

  // Group Transaction Page

  GROUP_TRANSACTION_TAB_CHANGED = 'Group Transaction: tab changed',
  GROUP_TRANSACTION_DETAILS_CLICKED = 'Group transaction details row clicked',
  GROUP_TRANSACTION_PAYMENT_DETAILS_DOWNLOAD_BUTTON_CLICKED = 'Group Transaction payment details download button clicked',
  GROUP_TRANSACTION_MARK_AS_PAID_CLICKED = 'Group Transaction mark as paid button clicked',
  GROUP_TRANSACTION_DISABLED_BUTTON_CLICKED = 'Group Transaction disabled button clicked',
  GROUP_TRANSACTION_DISABLE_MODAL_CONFIRM_CLICKED = 'Group Transaction disable modal confirm clicked',
  GROUP_TRANSACTION_SEND_REMINDER_BUTTON_CLICKED = 'Group Transaction send reminder button clicked',
  GROUP_TRANSACTION_SEND_REMINDER_MODAL_CONFIRM_CLICKED = 'Group Transaction send reminder modal confirm clicked',
  GROUP_TRANSACTION_PAYMENT_LINK_COPIED = 'Group Transaction payment link copied',
  GROUP_TRANSACTION_ENABLE_PARTIAL_PAYMENT_CONFIRM_CLICKED = 'Group Transaction enable partial payment confirm clicked',
  GROUP_TRANSACTION_ENABLE_PARTIAL_PAYMENT_CLICKED = 'Group Transaction enable partial payment button clicked'
}

export default Event;

export const PageNames = {
  [AUTH.LOGIN]: 'Login',
  [PAGE_NOT_FOUND]: 'Page not found'
};
