import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isAfter, isBefore, subDays } from 'date-fns';

import { useLazyGetCurrentSubscriptionQuery } from '@features/subscription/queries';
import { getLocalStorageItem } from '@utils/storage';
import { CurrentPlan } from 'types/subscription';
import { PlanStatus } from '@constants/subscription';
import UserPermissionTypes from '@constants/permissions';
import { formatISODate } from '@utils/date';

import { SubscriptionReminderState } from '../constants';

const usePlanExpiryToast = () => {
  const organizationId = getLocalStorageItem('organizationId');
  const { rolesAndPermissions } = useSelector((state: any) => state.user);

  const [getSubscription, { data }] = useLazyGetCurrentSubscriptionQuery();
  const currentSubscription = data as CurrentPlan;

  const localEndDate = useMemo(
    () =>
      currentSubscription?.end_date
        ? new Date(currentSubscription.end_date)
        : null,
    [currentSubscription?.end_date]
  );

  const today = useMemo(() => new Date(), []);

  const shouldShowNextPaymentAlert =
    !!localEndDate &&
    (isAfter(today, subDays(localEndDate, 5)) ||
      currentSubscription?.status === PlanStatus.EXPIRED) &&
    currentSubscription?.is_next_subscription_paid;

  const canUserPaySubscription = rolesAndPermissions?.permissions?.includes(
    UserPermissionTypes.SUBSCRIPTION_VIEW
  );

  const currentSubReminderState = useMemo(() => {
    if (currentSubscription?.status === PlanStatus.EXPIRED) {
      return SubscriptionReminderState.READ_ONLY;
    }

    if (localEndDate) {
      if (isBefore(today, localEndDate)) {
        return SubscriptionReminderState.DUE_DATE_UPCOMING;
      }
      if (!canUserPaySubscription) {
        return SubscriptionReminderState.CONTACT_ADMIN;
      }
      if (isAfter(today, localEndDate)) {
        return SubscriptionReminderState.GRACE_PERIOD;
      }
    }
    return null;
  }, [
    canUserPaySubscription,
    currentSubscription?.status,
    localEndDate,
    today
  ]);

  const planExpiryMessageMap = {
    [SubscriptionReminderState.DUE_DATE_UPCOMING]: `Please renew by ${formatISODate(
      { date: localEndDate || '' }
    )} to avoid any service interruptions.`,
    [SubscriptionReminderState.GRACE_PERIOD]:
      'Your plan has expired. Please renew for uninterrupted services.',
    [SubscriptionReminderState.READ_ONLY]:
      "You're now in read-only mode. Renew your subscription for full access.",
    [SubscriptionReminderState.CONTACT_ADMIN]:
      'Please contact your admin to renew for uninterrupted services.'
  };

  const planExpiryMessage = currentSubReminderState
    ? planExpiryMessageMap[currentSubReminderState]
    : null;

  useEffect(() => {
    getSubscription({ organizationId });
  }, [getSubscription, organizationId]);

  return {
    canUserPaySubscription,
    planExpiryMessage,
    shouldShowNextPaymentAlert,
    paymentLink: currentSubscription?.payment_link || ''
  };
};

export default usePlanExpiryToast;
