import React from 'react';

import { IconButton } from '@components/button/Button';
import { ToastProps } from 'types/toast';

import Icon from '../icon/Icon';

const typeStyles = {
  error: {
    icon: 'rounded-cross',
    background:
      'max-w-md shadow-shadowLg items-center rounded-lg bg-white px-4 py-3 border border-error'
  },
  success: {
    icon: 'rounded-tick',
    background:
      'max-w-md shadow-shadowLg items-center rounded-lg bg-white px-4 py-3 border border-primary'
  },
  warning: {
    icon: 'warning',
    background:
      'max-w-lg shadow-shadowLg items-center rounded-lg bg-white px-4 py-3 border border-warningYellow'
  }
};

const Toast: React.FC<ToastProps> = ({ type, message, onClose }) => (
  <div className={typeStyles[type].background} role="alert">
    <div className="flex w-full items-center">
      <div>
        <Icon name={typeStyles[type].icon} size="none" />
      </div>
      <div className="mx-2 text-base font-medium text-contentLite">
        {message}
      </div>
      <IconButton
        iconName="close"
        variant="transparent"
        handleClick={onClose}
        className="!h-fit !p-0"
      />
    </div>
  </div>
);

export default Toast;
