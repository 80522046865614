import { PaymentCollectionType } from '@features/group-management/constants';

export type DayOptionsType = {
  id: number;
  label: string;
  value: string;
};

// TODO: might change
export interface PaymentCollectionArg {
  interval: string;
  date: Date;
  type: PaymentCollectionType;
  isFutureDate?: boolean;
}

export interface CardProps {
  handleActivationDate: (date: Date, isFutureDate?: boolean) => void;
  handleIntervalFrequency: (frequency: string) => void;
  selectedDate?: Date;
  selectedFrequency?: number;
  type?: PaymentCollectionType;
  isTermDisabled?: boolean;
  nextDueDate?: any;
  minDate?: Date;
}

export enum PaymentCollectionComponentType {
  DEFAULT = 'default',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  BY_TERM = 'by_term',
  ONCE = 'once'
}

export interface PaymentCollectionProps {
  isOpen: boolean;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  cancelButtonStyle?: string;
  confirmButtonStyle?: string;
  handleConfirm: (item: PaymentCollectionArg) => void;
  handleCancel: () => void;
  type?: PaymentCollectionComponentType;
  className?: string;
  selectedDate?: Date;
  selectedtype?: PaymentCollectionType;
  selectedFrequency?: any;
  nextDueDate?: any;
}
