import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse } from '@types';

import { UpdateOrgDocStatusBody } from '../types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationDocuments: builder.query<
      HTTPResponse<any>,
      { organizationId: string }
    >({
      query: (reqBody) => ({
        url: END_POINTS.ADMIN.GET_ORGANIZATION_DOCUMENTS(
          reqBody.organizationId
        ),
        method: 'GET'
      })
    }),
    getAllOrganizations: builder.query<
      HTTPResponse<any>,
      { queryString: string }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.ADMIN.GET_ORGANIZATION_LIST}${reqBody.queryString}`,
        method: 'GET'
      })
    }),
    getAdminRolesAndPermissions: builder.query<HTTPResponse<any>, null>({
      query: () => ({
        url: END_POINTS.ADMIN.GET_ROLES_AND_PERMISSIONS,
        method: 'GET'
      })
    }),
    updateOrganizationStatus: builder.mutation<
      HTTPResponse<any>,
      { organizationId: string; body: { status: string } }
    >({
      query: (reqBody) => ({
        url: END_POINTS.ADMIN.UPDATE_ORGANIZATION_STATUS(
          reqBody.organizationId
        ),
        method: 'POST',
        body: reqBody.body
      })
    }),
    getOrganizationById: builder.query<
      HTTPResponse<any>,
      { organizationId: string }
    >({
      query: (reqBody) => ({
        url: END_POINTS.ADMIN.GET_ORGANIZATION_BY_ID(reqBody.organizationId),
        method: 'GET'
      })
    }),
    updateOrgDocStatus: builder.mutation<
      HTTPResponse<any>,
      { docId: string; body: UpdateOrgDocStatusBody }
    >({
      query: (req) => ({
        url: END_POINTS.ADMIN.UPDATE_ORG_DOC_STATUS(req.docId),
        method: 'PATCH',
        body: req.body
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetOrganizationDocumentsQuery,
  useLazyGetAllOrganizationsQuery,
  useLazyGetAdminRolesAndPermissionsQuery,
  useUpdateOrganizationStatusMutation,
  useGetOrganizationByIdQuery,
  useLazyGetOrganizationByIdQuery,
  useUpdateOrgDocStatusMutation
} = extendedBaseQuery;
