import { RadioCardType } from '@types';
import { ReactComponent as OrgAdminIcon } from '@assets/icons/organization-admin.svg';
import { ReactComponent as BranchAdminIcon } from '@assets/icons/branch-admin.svg';
import { ReactComponent as GroupAdminIcon } from '@assets/icons/group-admin.svg';
import { ReactComponent as FinanceAdminIcon } from '@assets/icons/finance-admin.svg';
import { ReactComponent as IndividualIcon } from '@assets/icons/individual.svg';
import { ReactComponent as OrganizationIcon } from '@assets/icons/organization.svg';
import { ReactComponent as BankIcon } from '@assets/icons/bank.svg';
import { ReactComponent as UpiIcon } from '@assets/icons/upi.svg';
import { ReactComponent as AttendanceManagerIcon } from '@assets/icons/attendance-manager.svg';

export const getIconName = {
  [RadioCardType.INDIVIDUAL]: IndividualIcon,
  [RadioCardType.ORGANIZATION]: OrganizationIcon,
  [RadioCardType.BANK]: BankIcon,
  [RadioCardType.UPI]: UpiIcon,
  [RadioCardType.ORGANISATION_ADMIN]: OrgAdminIcon,
  [RadioCardType.BRANCH_ADMIN]: BranchAdminIcon,
  [RadioCardType.GROUP_ADMIN]: GroupAdminIcon,
  [RadioCardType.FINANCE_ADMIN]: FinanceAdminIcon,
  [RadioCardType.ATTENDANCE_MANAGER]: AttendanceManagerIcon
};

export const getAccountLabel = {
  [RadioCardType.INDIVIDUAL]: 'Individual',
  [RadioCardType.ORGANIZATION]: 'Organization',
  [RadioCardType.BANK]: 'Bank Account',
  [RadioCardType.UPI]: 'UPI ID',
  [RadioCardType.ORGANISATION_ADMIN]: 'Organization Admin',
  [RadioCardType.BRANCH_ADMIN]: 'Branch Admin',
  [RadioCardType.GROUP_ADMIN]: 'Group Admin',
  [RadioCardType.FINANCE_ADMIN]: 'Finance',
  [RadioCardType.ATTENDANCE_MANAGER]: 'Attendance Manager'
};
