import {
  EditGroupType,
  GroupDetailsType,
  Member,
  FormDataType,
  MemberListType,
  PaymentCollectionComponentType,
  DateRange,
  AddMemberInput,
  FeeConfigurationType,
  FeeCategoryDataType
} from '@types';
import { capitalizeFirstLetter } from '@utils/stringFormat';
import { MonthDay, dayOptions } from '@components/payment-collection/constants';
import {
  formatISODate,
  getOrdinalDateString,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isTodayOrFutureDate
} from '@utils/date';
import { MonthArray } from '@constants/groups';
import {
  isInvalidAmount,
  isInvalidEmail,
  isInvalidGroupName,
  isInvalidMemberName
} from '@utils/validations';
import { checkArraysAreSame } from '@utils/generic';
import { getLocalStorageItem } from '@utils/storage';
import { CountryCodes } from '@components/country-code/constants';
import { isValidPhoneNumber } from '@helpers/generic';
import {
  handleMonthDateSelect,
  handleTermDateSelect,
  handleWeekDateSelect
} from '@components/payment-collection/helper';

import { defaultTemplateHeaders, PaymentCollectionType } from './constants';

export const getPaymentCollectionComponentType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionComponentType =
    PaymentCollectionComponentType.DEFAULT;
  if (frequency === 'monthly' && interval_frequency === 1) {
    paymentType = PaymentCollectionComponentType.MONTHLY;
  } else if (frequency === 'monthly' && interval_frequency > 1) {
    paymentType = PaymentCollectionComponentType.BY_TERM;
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionComponentType.WEEKLY;
  } else if (frequency === 'once')
    paymentType = PaymentCollectionComponentType.ONCE;
  return paymentType;
};

export const getDayType = (date: Date) => {
  const day = new Date(date);
  if (isFirstDayOfMonth(day)) return MonthDay.FIRST;
  if (isLastDayOfMonth(day)) return MonthDay.LAST;
  return MonthDay.CUSTOM;
};

const getCustomDate = (date: Date) =>
  getOrdinalDateString(String(new Date(date).getDate()));

const dueDatePlaceholderText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'the first day',
    last: 'the last day',
    custom: 'day'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'First day of the month',
    last: 'Last day of the month',
    custom: 'day of month'
  }
};

export const formatDueDatePlaceholder = (
  type: PaymentCollectionType,
  frequency: number,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `Weekly - ${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (type === PaymentCollectionType.ONCE) {
    return `Once - ${formatISODate({
      date: new Date(date),
      format: 'dd-mm-yyyy'
    })}`;
  }
  const suffix = dueDatePlaceholderText[type][dayType];
  const dateValue =
    dayType === 'custom' ? `${getCustomDate(date)} ${suffix}` : suffix;

  if (type === PaymentCollectionType.BY_TERM) {
    const everyXMonthsOn = `Every ${frequency} months on`;
    return `${everyXMonthsOn} ${dateValue}`;
  }

  return `Monthly - ${dateValue}`;
};

const dueDateDisplayText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'First day of every term',
    last: 'Last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'First day of month',
    last: 'Last day of month',
    custom: 'day of month'
  }
};

export const formatDueDateDisplayText = (
  type: PaymentCollectionType,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (type === PaymentCollectionType.ONCE) {
    return `Once - ${formatISODate({
      date: new Date(date),
      format: 'dd-mm-yyyy'
    })}`;
  }
  const suffix = dueDateDisplayText[type][dayType];
  const dateValue =
    dayType === 'custom' ? `${getCustomDate(date)} ${suffix}` : suffix;

  return `${dateValue}`;
};

export const getPaymentCollectionType = (
  frequency: string,
  interval_frequency: number
) => {
  let paymentType: PaymentCollectionType = PaymentCollectionType.MONTHLY;
  if (frequency === 'monthly') {
    if (interval_frequency === 1) {
      paymentType = PaymentCollectionType.MONTHLY;
    } else if (interval_frequency > 1) {
      paymentType = PaymentCollectionType.BY_TERM;
    }
  } else if (frequency === 'weekly') {
    paymentType = PaymentCollectionType.WEEKLY;
  } else if (frequency === 'once') {
    paymentType = PaymentCollectionType.ONCE;
  }
  return paymentType;
};

const dueDateTooltipText = {
  [PaymentCollectionType.BY_TERM]: {
    first: 'the first day of every term',
    last: 'the last day of every term',
    custom: 'of every term'
  },
  [PaymentCollectionType.MONTHLY]: {
    first: 'the first day of the month',
    last: 'the last day of the month',
    custom: 'of every month'
  }
};

export const formatDueDateTooltip = (
  type: PaymentCollectionType,
  date: Date
) => {
  const dayType = getDayType(date);
  if (type === PaymentCollectionType.WEEKLY)
    return `every ${capitalizeFirstLetter(
      dayOptions[new Date(date).getDay()].value
    )}`;
  if (type === PaymentCollectionType.ONCE) {
    return 'the due date';
  }
  if (dayType === 'custom') {
    return `the ${getCustomDate(date)} ${dueDateTooltipText[type][dayType]}`;
  }
  return dueDateTooltipText[type][dayType];
};

export const getMonthsTillCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const monthsUntilCurrent = MonthArray.slice(0, currentMonthIndex + 1);
  return monthsUntilCurrent;
};

export const isInvalidMemberList = (
  memberList: MemberListType[],
  maxAmountLimit: string,
  minAmountLimit: string,
  nameMaxLength: string,
  nameMinLength: string
) =>
  memberList.some(
    (member) =>
      (member.amount &&
        member.amount !== '0' &&
        isInvalidAmount(member.amount, maxAmountLimit, minAmountLimit)) ||
      isInvalidMemberName(member.name, nameMaxLength, nameMinLength) ||
      !member.primaryNumber ||
      (member.primaryNumber &&
        !isValidPhoneNumber(
          member.primaryNumberCountryCode as CountryCodes,
          member.primaryNumber
        )) ||
      (member.altNumber &&
        member.altNumber !== 'null' &&
        !isValidPhoneNumber(
          member.altNumberCountryCode as CountryCodes,
          member.altNumber
        ))
  );

export const invalidCSVErrorMessage =
  'Invalid data found. Update the uploaded file & try again.';

export const getFeeCategoryData = (
  feeCategoryData: {
    id: number;
    label: string;
    value: string;
  }[]
) =>
  feeCategoryData.map((item: any) => ({
    branch_fee_category_id: item.id,
    amount: item.value
  }));

export const findDueDateFromStartDate = (
  paymentCollectionType: string,
  dueDate: string,
  startDate: string
) => {
  if (paymentCollectionType === PaymentCollectionType.MONTHLY) {
    const { nextDueDate } = handleMonthDateSelect(
      new Date(dueDate).getDate().toString(),
      new Date(startDate || new Date())
    );
    return nextDueDate;
  }
  if (paymentCollectionType === PaymentCollectionType.WEEKLY) {
    const { nextDueDate } = handleWeekDateSelect(
      new Date(dueDate).getDay(),
      new Date(startDate || new Date())
    );
    return nextDueDate;
  }
  if (paymentCollectionType === PaymentCollectionType.BY_TERM) {
    const nextDueDate = handleTermDateSelect({
      item: new Date(dueDate).getDate().toString(),
      date: new Date(startDate || new Date())
    });
    return nextDueDate;
  }
  return '';
};

const getMemberDueDate = (
  dueDate: string,
  paymentCollectionType: string,
  startDate?: string,
  isFileUpload?: boolean
) => {
  if (dueDate) {
    if (
      isFileUpload &&
      startDate &&
      new Date(startDate).getTime() < new Date(dueDate).getTime()
    ) {
      return formatISODate({
        date: dueDate,
        separator: '-',
        format: 'yyyy-mm-dd'
      });
    }

    return formatISODate({
      date:
        startDate && isTodayOrFutureDate(startDate)
          ? findDueDateFromStartDate(paymentCollectionType, dueDate, startDate)
          : dueDate,
      separator: '-',
      format: 'yyyy-mm-dd'
    });
  }

  return '';
};

export const getParsedMemberData = ({
  memberList,
  paymentCollectionType,
  isFileUpload,
  isFeeCategoryEnabled,
  isAmountPaidTillDateEnabled,
  startDate
}: {
  memberList: MemberListType[];
  paymentCollectionType: string;
  isFileUpload?: boolean;
  isFeeCategoryEnabled?: boolean;
  isAmountPaidTillDateEnabled?: boolean;
  startDate?: string;
}) => {
  const isAmountPaidEmptyOrZero = memberList.every(
    (item) => item.amountPaid === '' || Number(item.amountPaid) === 0
  );

  const getAmountPaidTillDate = (member: MemberListType) => {
    if (!isFileUpload || isAmountPaidEmptyOrZero) {
      return null;
    }
    return member.amountPaid ? Number(member.amountPaid) : 0;
  };

  const hasValidAmount = (data: FeeCategoryDataType[]) =>
    data.every((item) => item.value !== undefined);

  return memberList.map((member) => ({
    name: member.name.trim(),
    mobile: member.primaryNumber,
    country_code: member.primaryNumberCountryCode,
    amount: Number(member.amount),
    activation_date: getMemberDueDate(
      member.dueDate,
      paymentCollectionType,
      startDate,
      isFileUpload
    ),
    alternate_mobile: member.altNumber,
    secondary_country_code: member.altNumberCountryCode,
    email: member.email,
    ...(isFeeCategoryEnabled &&
      member.feeCategoryData &&
      hasValidAmount(member.feeCategoryData) && {
        member_fee_configuration: getFeeCategoryData(
          member.feeCategoryData || []
        )
      }),
    ...(isAmountPaidTillDateEnabled && {
      amount_paid_till_date: getAmountPaidTillDate(member)
    })
  }));
};

export const getCreateGroupPayload = (
  formData: FormDataType,
  imageFilePath: string,
  memberList: MemberListType[],
  feeCategories: {
    id: number;
    label: string;
    value: string;
  }[],
  isFeeCategoryEnabled: boolean,
  isFileUpload: boolean,
  isAmountPaidTillDateEnabled: boolean
) => {
  const getIntervalValue = () => {
    if (formData.paymentCollectionType === PaymentCollectionType.WEEKLY)
      return PaymentCollectionType.WEEKLY;
    if (formData.paymentCollectionType === PaymentCollectionType.ONCE)
      return PaymentCollectionType.ONCE;
    return PaymentCollectionType.MONTHLY;
  };
  const isAmountPaidEmptyOrZero = memberList.every(
    (item) => item.amountPaid === '' || Number(item.amountPaid) === 0
  );

  const getSplitPaymentValue = () => {
    if (isAmountPaidTillDateEnabled && isFileUpload) {
      if (isAmountPaidEmptyOrZero) return formData?.paymentInstallment;
      return !!isAmountPaidTillDateEnabled;
    }
    return formData?.paymentInstallment;
  };

  const payload = {
    params: {
      organizationId: getLocalStorageItem('organizationId'),
      branchId: getLocalStorageItem('branchId')
    },
    body: {
      name: formData.groupName.trim(),
      image_url: imageFilePath,
      amount: Number(formData.amount),
      activation_date: formatISODate({
        date:
          formData?.startDate && isTodayOrFutureDate(formData?.startDate)
            ? findDueDateFromStartDate(
                formData.paymentCollectionType,
                formData.activationDate,
                formData.startDate
              )
            : formData.activationDate,
        separator: '-',
        format: 'yyyy-mm-dd'
      }),
      interval: getIntervalValue(),
      interval_frequency:
        formData.paymentCollectionType === PaymentCollectionType.BY_TERM
          ? Number(formData.intervalFrequency)
          : 1,
      members: getParsedMemberData({
        memberList,
        paymentCollectionType: formData.paymentCollectionType,
        isFileUpload,
        isFeeCategoryEnabled,
        isAmountPaidTillDateEnabled,
        ...(formData.startDate && { startDate: formData.startDate })
      }),
      is_split_payment: getSplitPaymentValue(),
      end_date: formData?.endDate
        ? formatISODate({
            date: formData.endDate,
            separator: '-',
            format: 'yyyy-mm-dd'
          })
        : null,
      start_date: formData?.startDate
        ? formatISODate({
            date: formData.startDate,
            separator: '-',
            format: 'yyyy-mm-dd'
          })
        : null,
      is_attendance_tracking_enabled: formData?.isAttandanceTrackingEnabled,
      is_send_attendance_alert_enabled: formData?.isSendAttendanceAlertEnabled,
      working_days: formData?.workingDays,
      is_fee_configured: isFeeCategoryEnabled,
      ...(isFeeCategoryEnabled && {
        group_fee_configuration: getFeeCategoryData(feeCategories)
      })
    }
  };

  return payload;
};

// input : date
// output: dayName eg: monday,tuesday...
export const getDayFromDate = (date: Date) => {
  const dayNumber = new Date(date).getDay();
  const day = dayOptions.find((item) => item.id === dayNumber)?.value;
  return day;
};

export const formatDate = (date: string) =>
  formatISODate({ date, separator: '-', format: 'yyyy-mm-dd' });

export const checkHasChangesInEditGroup = (
  groupInfo: EditGroupType,
  groupDetails: GroupDetailsType
) =>
  groupInfo.name !== groupDetails.name ||
  groupInfo.amount !== groupDetails.amount ||
  (groupInfo.groupPhoto.image_url || groupInfo.groupPhoto.preview || '') !==
    (groupDetails.image_url || '') ||
  groupInfo.next_due_at !== formatDate(groupDetails.next_due_at) ||
  groupInfo.frequency !== groupDetails.frequency ||
  groupInfo.interval_frequency !== groupDetails.interval_frequency ||
  groupInfo.paymentInstallment !== groupDetails.is_split_payment ||
  groupInfo.end_date !== groupDetails.end_date ||
  groupInfo.isAttandanceTrackingEnabled !==
    groupDetails.is_attendance_tracking_enabled ||
  groupInfo.isSendAttendanceAlertEnabled !==
    groupDetails.is_send_attendance_alert_enabled ||
  !checkArraysAreSame(
    groupInfo?.workingDays || [],
    groupDetails?.work_days || []
  );

export const checkHasChangesInEditMember = (
  memberDetails: MemberListType,
  member: Member,
  feeCategories: FeeConfigurationType[]
) => {
  const exisitngFeeConfig = member.fee_amount_configuration;

  const isFeeCategoryAmountDifferent =
    feeCategories &&
    feeCategories.some((feeCategory) => {
      const memberFee = exisitngFeeConfig?.find(
        (fee) => fee.category === feeCategory.category
      );
      return memberFee && memberFee.amount !== feeCategory.amount;
    });

  return (
    memberDetails.name !== member.name ||
    memberDetails.amount !== member.amount ||
    memberDetails.altNumber !== member.secondary_mobile ||
    memberDetails.dueDate !== formatDate(member.next_due_at) ||
    memberDetails.email !== member.email ||
    (feeCategories.length > 0 && isFeeCategoryAmountDifferent)
  );
};

export const getGroupInfo = (groupDetails: GroupDetailsType) => ({
  groupPhoto: {
    file: '',
    preview: '',
    image_url: groupDetails.image_url || ''
  },
  name: groupDetails.name,
  amount: groupDetails.amount,
  next_due_at: formatDate(groupDetails.next_due_at),
  frequency: groupDetails.frequency,
  interval_frequency: groupDetails.interval_frequency,
  paymentInstallment: groupDetails.is_split_payment,
  end_date: groupDetails.end_date,
  isAttandanceTrackingEnabled: !!groupDetails.is_attendance_tracking_enabled,
  isSendAttendanceAlertEnabled: !!groupDetails.is_send_attendance_alert_enabled,
  workingDays: groupDetails.work_days || []
});

export const getFormattedMemberData = (member: Member) => ({
  id: member.name + (member.phone || member.primary_mobile_number || ''),
  name: member.name || '',
  primaryNumber: member.phone || member.primary_mobile_number || '',
  altNumber: member.secondary_mobile_number || '',
  amount: member.amount || '',
  dueDate: member.next_due_at ? formatDate(member.next_due_at) : '',
  is_payment_link_sent: member.is_payment_link_sent || false,
  primaryNumberCountryCode: member.primary_country_code || CountryCodes.INDIA,
  altNumberCountryCode: member.secondary_country_code || CountryCodes.INDIA,
  email: member.email || ''
});

export const getAnalyticData = (
  groupData: any,
  formData: FormDataType,
  memberList: MemberListType[],
  memberMode: 'File' | 'Manual',
  showBranchInfoInBreadCrumbs: boolean,
  branchName: string,
  feeCategories: {
    id: number;
    label: string;
    value: string;
  }[],
  isFeeCategoryEnabled: boolean
) => ({
  group_name: groupData.name,
  type: formData.paymentCollectionType,
  interval: groupData.interval_frequency,
  payment_collection_date: groupData.activation_date,
  ...(formData.paymentCollectionType === PaymentCollectionType.WEEKLY && {
    payment_collection_day: getDayFromDate(new Date(groupData.activation_date))
  }),
  amount: groupData.amount,
  is_split_payment: groupData.is_split_payment,
  ...(getParsedMemberData({
    memberList,
    paymentCollectionType: formData.paymentCollectionType
  }).length > 0 && {
    add_member_mode: memberMode
  }),
  ...(showBranchInfoInBreadCrumbs && {
    branch_name: branchName
  }),
  ...(groupData?.end_date && {
    end_date: groupData.end_date
  }),
  members: getParsedMemberData({
    memberList,
    paymentCollectionType: formData.paymentCollectionType
  }),
  ...(isFeeCategoryEnabled && {
    feeCategories: getFeeCategoryData(feeCategories)
  })
});

export const formatMonthForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'MMMM YYYY' });

export const formatDateForAttendance = (date: string) =>
  formatISODate({ date, separator: ' ', format: 'DD MMMM YYYY' });

export const formatDateRange = (dateRange: DateRange) => {
  // adjust the time zone offset between local time zone and UTC
  const startOfMonthWithOffset = new Date(
    dateRange.startDate.getTime() -
      dateRange.startDate.getTimezoneOffset() * 60000
  ).toISOString();
  const endOfMonthWithOffset = new Date(
    dateRange.endDate.getTime() - dateRange.endDate.getTimezoneOffset() * 60000
  ).toISOString();
  return { startDate: startOfMonthWithOffset, endDate: endOfMonthWithOffset };
};

export const isAddNewGroupSaveDisabled = (
  formData: FormDataType,
  groupMemberMaxAmount: any,
  groupMemberMinAmount: any,
  feeCategories: { id: number; label: string; value: string }[],
  isFeeCategoryEnabled: boolean
) => {
  const areAllFeeCategoriesFilled =
    feeCategories.length > 0 &&
    feeCategories.every((category: any) => category.value.trim() !== '');

  return (
    isInvalidGroupName(formData.groupName) ||
    !formData.activationDate ||
    isInvalidAmount(
      formData.amount,
      groupMemberMaxAmount,
      groupMemberMinAmount
    ) ||
    (formData?.isAttandanceTrackingEnabled &&
      formData?.workingDays?.length === 0) ||
    (isFeeCategoryEnabled && !areAllFeeCategoriesFilled) ||
    (formData?.startDate &&
      formData?.endDate &&
      new Date(formData.startDate).setHours(0, 0, 0, 0) >
        new Date(formData.endDate).setHours(0, 0, 0, 0))
  );
};

const isValidDate = (year: number, month: number, day: number) => {
  const date = new Date(year, month, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
};

export const parseDateString = (dateString: string) => {
  // Check if date is in the format dd-mm-yyyy
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('-').map(Number);
    if (isValidDate(year, month - 1, day)) {
      return {
        date: new Date(Number(year), Number(month) - 1, Number(day)),
        error: ''
      };
    }
    return { date: null, error: 'Invalid Date' };
  }
  // Check if date is in the format yyyy-mm-dd
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(Number);
    if (isValidDate(year, month - 1, day)) {
      return {
        date: new Date(Number(year), Number(month) - 1, Number(day)),
        error: ''
      };
    }
    return { date: null, error: 'Invalid Date' };
  }

  return {
    date: null,
    error: 'Date should be in the format dd-mm-yyyy or yyyy-mm-dd'
  };
};

export const getValidationErrorsOnMemberDetails = (
  dataHeaders: string[],
  memberDetails: AddMemberInput[],
  maxAmount: number,
  minAmount: number,
  feeCategories: string[],
  isEmailEnabled: boolean,
  isAmountPaidTillDateEnabled: boolean,
  isOnce: boolean
): string[] => {
  // Validate Headers

  const templateHeaders = [
    ...defaultTemplateHeaders(
      feeCategories.length > 0,
      isEmailEnabled,
      isAmountPaidTillDateEnabled,
      isOnce
    ),
    ...feeCategories
  ];

  const countryCodeList = Object.values(CountryCodes).map(
    (country) => country as string
  );

  if (
    checkArraysAreSame(
      dataHeaders.filter((header) => header !== '' && !header.startsWith('_')),
      templateHeaders
    ) === false
  ) {
    return [
      `Column names don't match. Please ensure they follow the latest template.`
    ];
  }

  const errors: string[] = [];
  const primaryNumbers: string[] = [];
  const altNumbers: string[] = [];

  memberDetails.forEach((member: AddMemberInput, index: number) => {
    const {
      name,
      primaryNumber,
      altNumber,
      amount,
      dueDate,
      primaryNumberCode,
      altNumberCode,
      email,
      amountPaid
    } = member;

    // Validate name
    if (!name || name.length < 3) errors.push(`Row ${index + 1}: Invalid name`);

    // Validate primary number code
    if (
      !primaryNumberCode ||
      (primaryNumberCode && !countryCodeList.includes(`${primaryNumberCode}`))
    ) {
      errors.push(`Row ${index + 1}: Invalid Primary Number Country Code`);
    }

    const primaryNumberWithCode = `+${primaryNumberCode}${primaryNumber}`;

    //  validate primary number
    if (
      !primaryNumber ||
      (primaryNumber &&
        primaryNumberCode &&
        !isValidPhoneNumber(primaryNumberCode as CountryCodes, primaryNumber))
    )
      errors.push(`Row ${index + 1}: Invalid Primary number format`);

    // check if primary number and name are duplicate
    if (primaryNumbers.includes(`${name}_${primaryNumberWithCode}`))
      errors.push(`Row ${index + 1}: Duplicate Name and Primary Number`);

    // Validate alt number code
    if (altNumberCode && !countryCodeList.includes(`${altNumberCode}`)) {
      errors.push(`Row ${index + 1}: Invalid Alternative Number Country Code`);
    }

    const altNumberWithCode = `+${altNumberCode}${altNumber}`;
    // validate alternate number
    if (
      altNumber &&
      altNumberCode &&
      !isValidPhoneNumber(altNumberCode as CountryCodes, altNumber)
    )
      errors.push(`Row ${index + 1}: Invalid Alternative number format`);

    // check if alternate number is duplicate
    if (altNumbers.includes(`${name}_${altNumberWithCode}`))
      errors.push(`Row ${index + 1}: Duplicate Name Alternative Number`);

    // check primary and secondary numbers are same
    if (altNumber && primaryNumber === altNumber)
      errors.push(
        `Row ${index + 1}: Primary and Alternative Number should not be same`
      );

    // Validate email
    if (email && isInvalidEmail(email))
      errors.push(`Row ${index + 1}: Invalid Email`);

    if (feeCategories.length > 0) {
      // Check feeCatgories amount
      let totalAmount = 0;
      let hasEmptyCategoryValue = false;
      let hasNegativeValue = false;
      feeCategories.forEach((category) => {
        const categoryValue = member[category as keyof AddMemberInput];
        const isCategoryValueEmpty =
          categoryValue === undefined || categoryValue === null;
        if (!hasEmptyCategoryValue && isCategoryValueEmpty)
          hasEmptyCategoryValue = true;
        if (
          typeof categoryValue === 'number' &&
          categoryValue < 0 &&
          !hasNegativeValue
        )
          hasNegativeValue = true;
        if (categoryValue && Number(categoryValue).toLocaleString() === 'NaN') {
          errors.push(`Row ${index + 1}: Invalid amount for ${category}`);
        } else {
          totalAmount += Number(categoryValue);
        }
      });
      if (
        (!hasEmptyCategoryValue && totalAmount < minAmount) ||
        totalAmount > maxAmount ||
        hasNegativeValue
      )
        errors.push(
          `Row ${
            index + 1
          }: Amount should be between ${minAmount} and ${maxAmount}`
        );
    } else {
      if (amount && Number(amount).toLocaleString() === 'NaN')
        errors.push(`Row ${index + 1}: Invalid amount`);

      if (amount && (Number(amount) < minAmount || Number(amount) > maxAmount))
        errors.push(
          `Row ${
            index + 1
          }: Amount should be between ${minAmount} and ${maxAmount}`
        );
    }

    if (amountPaid && Number(amountPaid).toLocaleString() === 'NaN')
      errors.push(`Row ${index + 1}: Invalid amount paid`);
    else if (
      amountPaid &&
      (Number(amountPaid) < minAmount || Number(amountPaid) > maxAmount)
    )
      errors.push(
        `Row ${
          index + 1
        }: Amount paid should be between ${minAmount} and ${maxAmount}`
      );
    else if (
      amountPaid &&
      amount &&
      Number(amount).toLocaleString() !== 'NaN' &&
      Number(amountPaid) > Number(amount)
    )
      errors.push(
        `Row ${index + 1}: Amount paid should be less than or equal to Amount`
      );

    // Validate due date
    if (dueDate) {
      const { date, error } = parseDateString(dueDate);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
      if (date && date.getTime() < new Date().setHours(0, 0, 0, 0))
        errors.push(
          `Row ${index + 1}: Due date should be greater than or equal to today`
        );
    }

    // Add Primary Number and AltNumber to array
    if (primaryNumber) primaryNumbers.push(`${name}_${primaryNumberWithCode}`);
    if (altNumber) altNumbers.push(`${name}_${altNumberWithCode}`);
  });

  return errors;
};

export const formatGroupData = (data: any) => {
  const formattedActivationDate = formatISODate({
    date: data.next_due_at,
    separator: '-',
    format: 'yyyy-mm-dd'
  });
  const formattedEndDate = formatISODate({
    date: data.end_date,
    separator: '-',
    format: 'yyyy-mm-dd'
  });
  const groupFormData = {
    groupPhoto: {
      imageUrl: data.image_url || ''
    },
    groupName: data.name || '',
    activationDate: formattedActivationDate || '',
    intervalFrequency: data.interval_frequency || 0,
    amount: data.amount || 0,
    paymentInstallment: !!data.is_split_payment,
    paymentCollectionType: data.frequency || PaymentCollectionType.MONTHLY,
    endDate: formattedEndDate || '',
    isAttandanceTrackingEnabled: !!data.is_attendance_tracking_enabled,
    workingDays: data.work_days || [],
    isPaymentLinkSent: data.is_payment_link_sent,
    startDate: data.start_date || '',
    collectionStartedAt: data.collection_started_at,
    isSendAttendanceAlertEnabled: !!data.is_send_attendance_alert_enabled,
    isCollectionStarted: data.is_collection_started
  };
  return groupFormData;
};

export const formatFeeConfiguration = (
  groupFeeAmountConfiguration: Array<object>
) => {
  const feeConfiguration = groupFeeAmountConfiguration.map((item: any) => ({
    id: item.branch_fee_category_id,
    value: item.amount,
    label: item.category
  }));
  return feeConfiguration;
};
