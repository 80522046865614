import { Button } from '@components/index';
import usePlanExpiryToast from '../hooks/usePlanStatus';

const PlanExpiredToast = () => {
  const {
    canUserPaySubscription,
    planExpiryMessage,
    shouldShowNextPaymentAlert,
    paymentLink
  } = usePlanExpiryToast();

  return (
    <div>
      {shouldShowNextPaymentAlert && (
        <div className="mt-4 flex w-full items-center justify-between rounded-xl bg-yellowLite1 p-4 shadow-sm">
          <div className="flex cursor-default flex-col gap-1">
            <div className="text-base text-warningYellow">Renew Plan</div>
            <div className="text-grey">{planExpiryMessage}</div>
          </div>
          {canUserPaySubscription && (
            <Button
              label="Pay Now"
              handleClick={() => window.open(paymentLink, '_blank')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PlanExpiredToast;
