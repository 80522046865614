import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ORG_SETTINGS } from '@constants/routes';

const OrgSettings = lazy(() => import('@features/settings/pages/OrgSettings'));

export const OrgRoutes: RouteObject = {
  path: ORG_SETTINGS,
  element: <OrgSettings />
};

export default OrgRoutes;
