export const amountRegex = /^[1-9]\d*$/;

export const phoneNumberRegex = /^[6789][0-9]{9}$/;

export const invalidCharInNameRegex = /^[^<>'"\\/:!#=`\n]+$/;

export const groupNameRegex = /^[a-zA-Z0-9.,@_\s-]+$/;

export const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;

export const upiIdRegex = /^[\w.-]+@[\w.-]+$/;

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const gstinRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/;

export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export const nameRegex = /^[a-zA-Z0-9_-\s]+$/; // regex for user name, branch name, org name

export const phoneNumberRegexIndia = /^[6789][0-9]{9}$/;
export const phoneNumberRegexUAE = /^(50|52|54|55|56|58)[0-9]{7}$/;
export const phoneNumberRegexUS = /^[2-9][0-9]{2}[2-9][0-9]{6}$/;
