import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { BRANCHES } from '@constants/routes';

const Branches = lazy(
  () => import('@features/branch-management/pages/branches/Branches')
);
const AddNewBranch = lazy(
  () => import('@features/branch-management/pages/add-new-branch/AddNewBranch')
);

export const GroupRoutes: RouteObject[] = [
  {
    path: BRANCHES.BRANCHES,
    children: [
      {
        path: BRANCHES.BRANCHES,
        element: <Branches />
      },
      {
        path: BRANCHES.ADD_NEW_BRANCH,
        element: <AddNewBranch />
      },
      {
        path: BRANCHES.EDIT_BRANCH,
        element: <AddNewBranch />
      }
    ]
  }
];

export default GroupRoutes;
