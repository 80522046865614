const testId = {
  authentication: {
    authSection: {
      title: 'authentication_title',
      description: 'authentication_description',
      icon: 'authentication_icon'
    },
    loginInput: {
      privacyPolicyCheckbox: 'login_agreeTerms'
    },
    otpInput: {
      otpInputBoxes: 'otpVerify_otpInputs'
    },
    individualInfo: {
      useAboveNameOnReceiptToggle: 'newUser_toggleUseNameOnReceipt'
    }
  },
  header: {
    profileButton: 'header_profileButton',
    profileButtonIcon: 'header_profileIcon',
    profileDropdown: {
      dropdownIcon: 'header_profileDropdown_icon',
      dropdownName: 'header_profileDropdown_name',
      dropdownNumber: 'header_profileDropdown_phoneNumber'
    },
    logoutPopup: {
      confirm: 'header_logoutPopup_confirmLogout',
      cancel: 'header_logoutPopup_cancelLogout'
    },
    upgradeToOrgPopup: {
      confirm: 'header_upgradeToOrgPopup_confirm',
      cancel: 'header_upgradeToOrgPopup_cancel'
    },
    switchAccountModal: {
      continue: 'header_switchAccountModal_continue',
      cancel: 'header_switchAccountModal_cancel'
    }
  },
  transactionsPage: {
    filterDropdown: 'transactions_filterDropdown'
  },
  groupsPage: {
    newGroupButton: 'groups_newGroupButton',
    groupSearchInput: 'groups_groupSearchInput',
    groupFilterDropdown: 'groups_groupFilterDropdown',
    groupCard: 'groups_groupCard',
    upgradeToCofeeModal: {
      panRadioButton: 'groups_addKycPopup_panBtn',
      gstinRadioButton: 'groups_addKycPopup_gstinBtn'
    },
    kycConfirmPopup: {
      confirmButton: 'groups_kycConfirmPopup_confirm',
      cancelButton: 'groups_kycConfirmPopup_cancel'
    }
  },
  groupDetailsPage: {
    summarySection: {
      memberCount: 'groupDetails_memberCount',
      recurrs: 'groupDetails_recurrs',
      endDate: 'groupDetails_endDate',
      startDate: 'groupDetails_startDate',
      dueDate: 'groupDetails_dueDate',
      amount: 'groupDetails_amount'
    }
  }
};
export default testId;
